@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "animate.css";
@font-face {
  font-family: "SFProText-Regular";
  src: url("./assets/fonts/SFProText-Regular.ttf");
}
* {
  font-family: "SFProText-Regular" !important;
}

ion-card {
  margin-inline: unset;
}

#backdrop {
  z-index: 2;
  opacity: 0.5;
  background: #000;
}

#progress-bar {
  z-index: 3;
  opacity: 1;
  height: 6px;
}

.alert-wrapper.sc-ion-alert-ios {
  --max-width: 400px;
  width: 100%;
}

.alert-title.sc-ion-alert-ios-h {
  --max-width: 300px;
}

.alert-title.sc-ion-alert-ios {
  color: #00a7ba;
  font-size: 18px;
}

.alert-message.sc-ion-alert-ios {
  color: #004850;
  font-size: 16px;
}

.alert-button.sc-ion-alert-ios {
  color: #004850;
}

.alert-button.sc-ion-alert-ios:last-child {
  color: #00a7ba;
}

[aria-checked=true].sc-ion-alert-ios .alert-checkbox-icon.sc-ion-alert-ios {
  border-color: #00a7ba;
  background-color: #00a7ba;
}

[aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: #00a7ba;
}

[aria-checked=true].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
  border-color: #00a7ba;
}

.alert-tappable.sc-ion-alert-ios {
  text-align: center;
}

.alert-radio-label.sc-ion-alert-ios {
  margin-right: -30px;
  color: #004850;
  font-weight: 600;
  white-space: break-spaces;
}

.irDownload {
  color: #00a7ba !important;
  font-weight: bold !important;
}

.contactDelete {
  color: #eb445a !important;
  font-weight: bold !important;
}

.irWhatsApp {
  color: #34af23 !important;
  background-image: url("assets/icon/logo-whatsapp.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  zoom: 90%;
}

.toast {
  --start: none;
}

.toastLogin {
  --start: none;
  --color: #ffffff;
  color: #ffffff;
}

.picker-button.sc-ion-picker-ios,
.picker-button.ion-activated.sc-ion-picker-ios {
  color: #00a7ba;
}

.picker-opt {
  color: #004850;
  font-weight: 600;
}

.picker-above-highlight.sc-ion-picker-ios {
  border-bottom: 1px solid #00a7ba;
}

.picker-below-highlight.sc-ion-picker-ios {
  border-top: 1px solid #00a7ba;
}

.picker-wrapper.sc-ion-picker-ios {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .toast {
    --start: 10px;
  }

  .classModalNotification {
    --backdrop-opacity: 0 !important;
    --width: 100vw !important;
    --max-width: 100vw !important;
    --min-height: 250px !important;
    --height: calc(100vh - 131px) !important;
    --max-height: calc(100vh - 131px) !important;
    --border-radius: 0px;
  }

  .classModalNotification .modal-wrapper.sc-ion-modal-ios {
    margin: 80px auto auto !important;
    border-radius: 0 !important;
  }

  .modalIR {
    --background: transparent !important;
    background: #ffffff url("./assets/background/bg-v.png") no-repeat top right/cover !important;
  }

  .modalRedes {
    --background: transparent !important;
    background: #ffffff url("./assets/background/bg-v.png") no-repeat top right/cover !important;
  }

  .modalBoleto {
    --background: transparent !important;
    background: #ffffff url("./assets/background/bg-v.png") no-repeat top right/cover !important;
  }
}
.alertSolicitacao {
  --height: 300px;
}

.alertSolicitacao textarea.alert-input.sc-ion-alert-ios {
  height: 110px;
}

.alertAdesão {
  --height: auto;
}

.alertAdesão textarea.alert-input.sc-ion-alert-ios {
  height: 110px;
}

.alertAdesão .alert-tappable.sc-ion-alert-ios {
  text-align: center;
  border-bottom: 1px dashed #004850;
  margin: 0 10px;
  width: calc(100% - 20px);
}

.alertAdesão .alert-radio-group.sc-ion-alert-ios,
.alertAdesão .alert-checkbox-group.sc-ion-alert-ios {
  padding-bottom: 30px;
  border-top-color: #004850;
}

.alertAdesão .alert-radio-label.sc-ion-alert-ios {
  white-space: break-spaces;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  color: #004850;
}

.classModalNotification {
  --backdrop-opacity: 0 !important;
  --width: 360px;
  --max-width: 360px;
  --min-height: 250px;
  --height: calc(100vh - 100px);
  --max-height: calc(100vh - 100px);
  --border-radius: 0px 0px 10px 10px;
}

.classModalNotification .modal-wrapper.sc-ion-modal-ios {
  margin: 80px 20px auto auto;
  box-shadow: 0.5px 1px 4px 0px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
}

.iconDate input[type=date]::-webkit-inner-spin-button,
.iconDate input[type=date]::-webkit-calendar-picker-indicator {
  filter: brightness(0) saturate(100%) invert(18%) sepia(94%) saturate(874%) hue-rotate(150deg) brightness(97%) contrast(101%);
  font-size: 20px;
  color: #004850;
  --color: #004850;
  opacity: 1;
  display: block;
  background: url("assets/icon/calendar-outline.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  display: block;
}

.iconDate input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0px;
}

.iconDate input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 15px;
}

.iconDate input::-webkit-datetime-edit {
  position: relative;
  left: 15px;
  color: #00a7ba;
}

.iconDate input[type=date]::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  color: #004850;
  --placeholder-color: #004850;
}

.iconDate input[type=date]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #004850;
  --placeholder-color: #004850;
}

.iconDate input[type=date]::placeholder {
  font-size: 14px;
  color: #004850;
  --placeholder-color: #004850;
}

.popover-content.sc-ion-popover-ios {
  width: auto;
}

.sc-ion-popover-ios-h {
  --width: auto !important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.swiper-pagination {
  bottom: 60px !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  opacity: 0.4;
}

.grecaptcha-badge {
  visibility: hidden;
}

.card-auth .itemInput ion-input input::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  color: #004850;
}

.card-auth .itemInput ion-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #004850;
}

.card-auth .itemInput ion-input input::placeholder {
  font-size: 14px;
  color: #004850;
}

.col3-card-content ion-card ion-card-content ion-item .input .native-input[disabled].sc-ion-input-ios {
  opacity: 1 !important;
}

.inputValor .native-input[disabled].sc-ion-input-ios {
  opacity: 1 !important;
}

.modalViewer ion-button {
  --color: #00a7ba !important ;
}

.modalIR {
  --background: transparent !important;
  background: #ffffff url("./assets/background/bg.png") no-repeat bottom/cover;
  --height: 100vh;
  --overflow: auto;
}
.modalIR ion-content {
  min-height: max-content;
}

.modalRedes {
  --background: transparent !important;
  background: #ffffff url("./assets/background/bg.png") no-repeat bottom/cover;
  --height: 100vh;
  --overflow: auto;
}
.modalRedes .modal-wrapper {
  width: 100%;
  border-radius: 0;
}
.modalRedes ion-content {
  min-height: max-content;
}

.modalBoleto {
  --background: transparent !important;
  background: #ffffff url("./assets/background/bg.png") no-repeat bottom/cover;
  --height: 100vh;
  --overflow: auto;
}
.modalBoleto ion-content {
  min-height: max-content;
}

ion-content ion-list ion-grid ion-row ion-col ion-item .input .native-input[disabled].sc-ion-input-ios {
  opacity: 1 !important;
}

#speechTitle {
  display: flex;
  overflow: inherit !important;
  max-width: 60% !important;
  width: max-content;
  min-height: auto !important;
  height: min-content;
  margin: 0 0 15px;
  --min-height: auto;
  --padding-start: 0;
  --inner-padding-end: 0;
  color: #004850;
  font-size: 14px;
  font-weight: 400;
  background: #e0e0e0 !important;
  --background: #e0e0e0 !important;
  padding: 15px 20px;
  text-align: left;
  border-radius: 15px 15px 15px 10px;
}
#speechTitle ion-label {
  margin: 0;
  padding: 0;
}

#speechTitle:before {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 0px solid #e0e0e0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #e0e0e0;
  left: -20px;
  bottom: 0px;
  rotate: -20deg;
}

#speechMessage {
  display: flex;
  overflow: inherit !important;
  max-width: 60% !important;
  width: max-content;
  min-height: auto !important;
  height: min-content;
  margin: 0 0 15px;
  --min-height: auto;
  --padding-start: 0;
  --inner-padding-end: 0;
  color: #004850;
  font-size: 14px;
  font-weight: 400;
  background: #e0e0e0 !important;
  --background: #e0e0e0 !important;
  padding: 15px 20px;
  text-align: left;
  border-radius: 15px 15px 15px 10px;
}
#speechMessage ion-label {
  margin: 0;
  padding: 0;
}

#speechMessage:before {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 0px solid #e0e0e0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #e0e0e0;
  left: -20px;
  bottom: 0px;
  rotate: -20deg;
}

.speechMessage {
  display: flex;
  overflow: inherit !important;
  max-width: 60% !important;
  width: max-content;
  min-height: auto !important;
  margin: 0 0 15px;
  --min-height: auto;
  --padding-start: 0;
  --inner-padding-end: 0;
  color: #004850;
  font-size: 14px;
  font-weight: 400;
  background: #e0e0e0 !important;
  --background: #e0e0e0 !important;
  padding: 15px 20px;
  text-align: left;
  border-radius: 15px 15px 15px 10px;
}
.speechMessage ion-label {
  margin: 0;
  padding: 0;
}

.speechMessage:before {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 0px solid #e0e0e0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #e0e0e0;
  left: -20px;
  bottom: 0px;
  rotate: -20deg;
}

#selectEspecialidadeItem ion-select {
  color: #004850;
  --color: #004850;
  --placeholder-color: #004850;
  --placeholder-opacity: 1;
  --padding-start: 0;
  --padding-end: 0;
  max-width: 100%;
}

#selectProdutoItem ion-select {
  color: #004850;
  --color: #004850;
  --placeholder-color: #004850;
  --placeholder-opacity: 1;
  --padding-start: 0;
  --padding-end: 0;
  max-width: 100%;
}

#selectEstadoItem ion-select {
  color: #004850;
  --color: #004850;
  --placeholder-color: #004850;
  --placeholder-opacity: 1;
  --padding-start: 0;
  --padding-end: 0;
  max-width: 100%;
}

#selectCidadeItem ion-select {
  color: #004850;
  --color: #004850;
  --placeholder-color: #004850;
  --placeholder-opacity: 1;
  --padding-start: 0;
  --padding-end: 0;
  max-width: 100%;
}

#digitando {
  width: max-content;
  color: transparent;
  font-size: 14px;
  font-weight: 500;
  margin-left: -5px;
  border-right: 2px solid #004850;
  white-space: nowrap;
  overflow: hidden;
  animation: mark 0.5s step-end infinite;
}

.btnType {
  margin: 0 5px 15px 0;
  font-size: 14px;
}

.btnTypeSelected {
  float: right;
  margin: 0;
  opacity: 1;
  font-size: 14px;
  --border-radius: 6px 6px 0px 6px;
}

.opacity-6 {
  opacity: 0.6 !important;
}

@media only screen and (max-width: 767px) {
  #speechTitle {
    max-width: 90% !important;
    padding: 10px 15px;
  }

  #speechMessage {
    max-width: 90% !important;
    padding: 10px 15px;
  }

  .speechMessage {
    max-width: 90% !important;
    padding: 10px 15px;
  }

  .alert-wrapper.sc-ion-alert-ios {
    --max-width: 100vw;
    width: 80%;
  }
}